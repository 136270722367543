html,
body {
  height: 100vh;
  padding: 0px;
  margin: 0px;
  font-family: 'Inter', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

*>* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
}